import React, { useState } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import { contract_address, contract_abi } from "./contract.js";

const web3miami = () => {

  const [web3Provider, setProvider] = useState(null);
  const [address, setAddress] = useState('');
  const [addressDisplay, setDisplayAddress] = useState('CONNECT WALLET');
  const [contract, setContract] = useState('');

  const wallet_connect = async () => {
    if (Web3.givenProvider) {
      // init
      const providerOptions = {
        // Do nothing
      };

      const web3Modal = new Web3Modal({
        providerOptions, // required
      });

      const provider = await web3Modal.connect();
      const web3 = new Web3(provider);
      setProvider(web3);

      // swap to eth:mainnet
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: 0x1 }],
      });

      // set address
      const addresses = await web3.eth.getAccounts();
      setAddress(addresses[0]);

      // set display address
      var startString = address.substring(0,4);
      var dots ="…";
      var endString = address.substring(selectedAccount.length - 4)
      var display = startString+dots+endString;
      setDisplayAddress(display);

      // set contract instance
      const contract = new web3.eth.Contract(contract_abi, contract_address);
      setContract(contract);
    }
  }

  const mint = async () => {
    if (Web3.givenProvider) {
      let price = await contract.methods.MINT_PRICE().call();

      try {
        const estimateGas = await contract.methods.publicMint().estimateGas({
          from: address,
          value: price,
        });

        const result = await contract.methods.publicMint().send({
          from: address,
          value: price,
          gas: estimateGas
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  return {web3Provider, address, addressDisplay, contract, wallet_connect}
}

export default web3miami;
