import metaverse from "../_assets/images/metaverse-img.jpg";

const PassInfo = () => {
  return (
    <>
      <div className="web3nav" id="marketplace">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>
                A Web3 Native <span>Metaverse, NFT,</span> and <span>Art</span>{" "}
                Innovation Conference
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="sec-w">
        <div className="container">
          <div className="row row-reverse640">
            <div className="col-md-5 met-img">
              <img src={metaverse} alt="" />
            </div>
            <div className="col-md-7">
              <h2>
                OUR UNIQUE <span>GENESIS PASS</span> NFT
              </h2>
              <p>
                Our GENESIS PASS is being minted as an NFT (non-fungible token)
                on the Ethereum blockchain. There will be a total of 3,600
                passes available during our genesis minting process, 600 of
                which will be our special VIP pass with additional benefits.
                These VIP passes will be randomized within the ticket minting
                pool and feature their own unique graphic. This means everyone
                who mints has an equal chance of scoring a VIP pass.
                Additionally, you may sell your ticket on the open market via
                numerous marketplace platforms such as OpenSea™ or LooksRare™.
                This means that you could potentially recover your entire ticket
                price after the event! Finally, if you cannot make it to
                Metaverse Miami this year, next year, etc........why not rent
                your ticket to someone else so they can attend Metaverse Miami?
                We will have a dedicated rental system for those who want us to
                handle this aspect for them!
              </p>
              <h4>You OWN your experience!</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PassInfo;
