import img1 from "../_assets/images/keithgrossman.jpg";
import img2 from "../_assets/images/avery.jpg";
import img3 from "../_assets/images/julian.jpg";
import img4 from "../_assets/images/farokh.jpg";
import img5 from "../_assets/images/dickerman.jpg";
import img6 from "../_assets/images/bobbyhundreds.jpg";
import img7 from "../_assets/images/wang.jpg";
import img8 from "../_assets/images/mando.jpg";
import img9 from "../_assets/images/swan.jpg";
import img10 from "../_assets/images/osf.jpg";
import img11 from "../_assets/images/pio.jpg";
import img12 from "../_assets/images/swopes.jpg";
import img13 from "../_assets/images/nate.jpg";
import img14 from "../_assets/images/vgf.jpg";
import img15 from "../_assets/images/kix.jpg";
import img16 from "../_assets/images/allnick.jpg";
import img17 from "../_assets/images/simon.jpg";
import img18 from "../_assets/images/lenna.jpg";
import img19 from "../_assets/images/illa.jpg";
import img20 from "../_assets/images/mec.jpg";
import img21 from "../_assets/images/asad.jpg";
import img22 from "../_assets/images/tropo.jpg";
import img23 from "../_assets/images/lindsey.jpg";
import img24 from "../_assets/images/jeff.jpg";
import img25 from "../_assets/images/laura.jpg";
import img26 from "../_assets/images/jonah.jpg";
import img27 from "../_assets/images/hunter.jpg";
import img28 from "../_assets/images/brycent.jpg";
import img29 from "../_assets/images/soby.jpg";
import img30 from "../_assets/images/goku.jpg";
import img31 from "../_assets/images/sh3.jpg";
import img32 from "../_assets/images/cory.jpg";
import img33 from "../_assets/images/spottie.jpg";
import img34 from "../_assets/images/silva.jpg";
import img35 from "../_assets/images/profits.jpg";
import img36 from "../_assets/images/bt.jpg";
import img37 from "../_assets/images/shontelle.jpg";
import img38 from "../_assets/images/ldf.jpg";
import img39 from "../_assets/images/gino.jpg";
import img40 from "../_assets/images/nicole.jpg";
import img41 from "../_assets/images/david.jpg";
import img42 from "../_assets/images/illmind.jpg";
import img43 from "../_assets/images/maiko.jpg";
import img44 from "../_assets/images/legend.jpg";
import img45 from "../_assets/images/degentraland.jpg";
import img46 from "../_assets/images/boredelon.jpg";
import img47 from "../_assets/images/boredbecky.jpg";
import img48 from "../_assets/images/kenobi.jpg";
import img49 from "../_assets/images/shant.jpg";
import img50 from "../_assets/images/nitty.jpg";
import img51 from "../_assets/images/parin.jpg";
import img52 from "../_assets/images/stein.jpg";
import img53 from "../_assets/images/willie.jpg";
import img54 from "../_assets/images/rich.jpg";
import img55 from "../_assets/images/lastraum.jpg";
import img56 from "../_assets/images/shawn.jpg";
import img57 from "../_assets/images/carlo.jpg";
import img58 from "../_assets/images/bandit.jpg";
import img59 from "../_assets/images/norman.jpg";
import img60 from "../_assets/images/melillo.jpg";
import img61 from "../_assets/images/ellie.jpg";
import img62 from "../_assets/images/gangland.jpg";
import img63 from "../_assets/images/neer.jpg";
import img64 from "../_assets/images/leah.jpg";
import img65 from "../_assets/images/ignition.jpg";
import img66 from "../_assets/images/tatted.jpg";
import img67 from "../_assets/images/rendr.jpg";
import img68 from "../_assets/images/giovanni.jpg";
import img69 from "../_assets/images/issy.jpg";
import img70 from "../_assets/images/swaggins.jpg";
import img71 from "../_assets/images/kai.jpg";
import img72 from "../_assets/images/austin.jpg";
import img73 from "../_assets/images/brooke.jpg";
import img74 from "../_assets/images/christine.jpg";
import img75 from "../_assets/images/benny.jpg";
import img76 from "../_assets/images/brittney.jpg";
import img77 from "../_assets/images/alexander.jpg";
import img78 from "../_assets/images/creative.jpg";
import img79 from "../_assets/images/rocsol.jpg";
import img80 from "../_assets/images/nftanon.jpg";
import img81 from "../_assets/images/katherine.jpg";
import img82 from "../_assets/images/jyothee.jpg";
import img83 from "../_assets/images/russkiy.jpg";
import img84 from "../_assets/images/zeflorist.jpg";
import img85 from "../_assets/images/techrabbi.jpg";
import img86 from "../_assets/images/gav.jpg";
import img87 from "../_assets/images/serpunk.jpg";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

const Speaker = () => {
  const [Images, SetImages] = useState([]);
  const [end, SetEnd] = useState(24);
  const [hideButton, SetHideButton] = useState(false);
  const images = [
    {
      img: img1,
      name: "KEITH GROSSMAN",
      info: "President | TIME",
      twitter: "https://twitter.com/keithgrossman",
    },
    {
      img: img2,
      name: "AVERY AKKINENI",
      info: "President | Vayner3",
      twitter: "https://twitter.com/avery_akkineni",
    },

    {
      img: img3,
      name: "JULIAN HOLGUIN",
      info: "CEO | Doodles",
      twitter: "https://twitter.com/jholguin",
    },

    {
      img: img4,
      name: "FAROKH",
      info: "Founder | Rug Radio",
      twitter: "https://twitter.com/farokh",
    },
    {
      img: img5,
      name: "ROGER DICKERMAN",
      info: "Founder | Artifex",
      twitter: "https://twitter.com/rogerdickerman",
    },
    {
      img: img6,
      name: "BOBBY HUNDREDS",
      info: "Founder | The Hundreds",
      twitter: "https://twitter.com/bobbyhundreds",
    },
    {
      img: img7,
      name: "ANDREW WANG",
      info: "Devotion",
      twitter: "https://twitter.com/andr3w",
    },
    {
      img: img8,
      name: "MANDO",
      info: "Canary Labs",
      twitter: "https://twitter.com/greatmando_nft",
    },
    {
      img: img9,
      name: "SWAN SIT",
      info: "The Queen of Clubhouse",
      twitter: "https://twitter.com/swansit",
    },
    {
      img: img10,
      name: "OSF",
      info: "Canary Labs",
      twitter: "https://twitter.com/osf_nft",
    },
    {
      img: img11,
      name: "PIO",
      info: "The Nifty Alpha Show",
      twitter: "https://twitter.com/piovincenzo_",
    },
    {
      img: img12,
      name: "ELISE SWOPES",
      info: "Artist",
      twitter: "https://twitter.com/swopes",
    },
    {
      img: img13,
      name: "NATE ALEX",
      info: "Founder | SPT",
      twitter: "https://twitter.com/natealexnft",
    },
    {
      img: img14,
      name: "VGF",
      info: "Pixel Vault",
      twitter: "https://twitter.com/vgfreakxbl",
    },
    {
      img: img15,
      name: "KIX",
      info: "The Nifty Alpha Show",
      twitter: "https://twitter.com/speculatorart",
    },
    {
      img: img16,
      name: "NICK",
      info: "The Nifty Alpha Show",
      twitter: "https://twitter.com/allnick",
    },
    {
      img: img17,
      name: "SIMON GOLDBERG",
      info: "Web3 Strategist",
      twitter: "https://twitter.com/simon_goldberg",
    },
    {
      img: img18,
      name: "LENNA",
      info: "CoFounder | ClayMates",
      twitter: "https://twitter.com/lenna_clay",
    },
    {
      img: img19,
      name: "ILLA DA PRODUCER",
      info: "?",
      twitter: "https://twitter.com/illadaproducer",
    },
    {
      img: img20,
      name: "MEC",
      info: "Web3 Educator",
      twitter: "https://twitter.com/mecdot",
    },
    {
      img: img21,
      name: "ASAD J MALIK",
      info: "CEO | Jadu",
      twitter: "https://twitter.com/asadjmalik",
    },
    {
      img: img22,
      name: "TROPOFARMER",
      info: "NFT Strategist",
      twitter: "https://twitter.com/tropofarmer",
    },
    {
      img: img23,
      name: "LINDSEY BYRNES",
      info: "Photographer",
      twitter: "https://twitter.com/lindseybyrnes",
    },
    {
      img: img24,
      name: "JEFF NICHOLAS",
      info: "WarpSound",
      twitter: "https://twitter.com/_jeffnicholas_",
    },
    {
      img: img25,
      name: "LAURA ROD",
      info: "The Miami Ape",
      twitter: "https://twitter.com/themiamiape",
    },
    {
      img: img26,
      name: "JONAH BLAKE",
      info: "Game Fund Partners",
      twitter: "https://twitter.com/realjonahblake",
    },
    {
      img: img27,
      name: "HUNTER ORRELL",
      info: "Future Proof",
      twitter: "https://twitter.com/hunterorrell",
    },
    {
      img: img28,
      name: "BRYCENT",
      info: "Loot Squad",
      twitter: "https://twitter.com/brycent",
    },
    {
      img: img29,
      name: "SOBY",
      info: "Expopulus",
      twitter: "https://twitter.com/sobylife",
    },
    {
      img: img30,
      name: "GOKU",
      info: "The GM Show",
      twitter: "https://twitter.com/goku_nft",
    },
    {
      img: img31,
      name: "SH3K1N4H",
      info: "Attorney / Creator",
      twitter: "https://twitter.com/sh3k1n4h",
    },
    {
      img: img32,
      name: "CORY VAN LEW",
      info: "Artist",
      twitter: "https://twitter.com/coryvanlew",
    },
    {
      img: img33,
      name: "JULIAN HOLGUIN",
      info: "CEO | Doodles",
      twitter: "https://twitter.com/jholguin",
    },
    {
      img: img34,
      name: "JN SILVA",
      info: "Founder | Animus",
      twitter: "https://twitter.com/JNSilva_",
    },
    {
      img: img35,
      name: "SHANICE BEST",
      info: "Web3 Educator",
      twitter: "https://twitter.com/_shanicebest",
    },
    {
      img: img36,
      name: "BT",
      info: "Music Artist",
      twitter: "https://twitter.com/bt",
    },
    {
      img: img37,
      name: "SHONTELLE",
      info: "Music Artist",
      twitter: "https://twitter.com/sohtelle_layne",
    },
    {
      img: img38,
      name: "LDF",
      info: "GM House",
      twitter: "https://twitter.com/ldf_gm",
    },
    {
      img: img39,
      name: "GINO THE GHOST",
      info: "Writer / Producer",
      twitter: "https://twitter.com/ginotheghost",
    },
    {
      img: img40,
      name: "NICOLE BEHNAM",
      info: "HashFlow",
      twitter: "https://twitter.com/nicolebehnam",
    },

    {
      img: img41,
      name: "DAVID BIANCHI",
      info: "Film Maker",
      twitter: "https://twitter.com/davidbianchi",
    },
    {
      img: img42,
      name: "ILL MIND PRODUCER",
      info: "Producer",
      twitter: "https://twitter.com/illmindproducer",
    },
    {
      img: img43,
      name: "MICHAEL LE",
      info: "Joystick",
      twitter: "https://twitter.com/justmaiko",
    },
    {
      img: img44,
      name: "POST VERRONE",
      info: "DoodlDuckz",
      twitter: "https://twitter.com/legend",
    },
    {
      img: img45,
      name: "DEGENTRALAND",
      info: "Web3 Educator",
      twitter: "https://twitter.com/degentraland",
    },
    {
      img: img46,
      name: "BORED ELON MUSK",
      info: "Bored Box",
      twitter: "https://twitter.com/boredelonmusk",
    },
    {
      img: img47,
      name: "BORED BECKY",
      info: "Fame Lady Squad",
      twitter: "https://twitter.com/iamboredbecky",
    },
    {
      img: img48,
      name: "KENOBI",
      info: "CoFounder | Metaverse Miami",
      twitter: "https://twitter.com/kenobidesign",
    },
    {
      img: img49,
      name: "SHANT MAROOTIAN",
      info: "COO | Tessera",
      twitter: "https://twitter.com/smaroo",
    },
    {
      img: img50,
      name: "NITTY B",
      info: "NFT Collector",
      twitter: "https://twitter.com/nittyb_xyz",
    },
    {
      img: img51,
      name: "PARIN HEIDARI",
      info: "Artist",
      twitter: "https://twitter.com/parinheidari",
    },
    {
      img: img52,
      name: "JEN STEIN",
      info: "Web3 Educator",
      twitter: "https://twitter.com/adultarts",
    },
    {
      img: img53,
      name: "WILLIE",
      info: "Founder | Happy Goat",
      twitter: "https://twitter.com/morewillie",
    },
    {
      img: img54,
      name: "RICH CARBARA",
      info: "ReadyPlayerDAO",
      twitter: "https://twitter.com/readyplayerrich",
    },
    {
      img: img55,
      name: "LASTRAUM",
      info: "Last Slice Co",
      twitter: "https://twitter.com/lastraum",
    },
    {
      img: img56,
      name: "SHAWN COSS",
      info: "Artist",
      twitter: "https://twitter.com/shawncoss",
    },
    {
      img: img57,
      name: "CARLO DEANGELO",
      info: "Attorney",
      twitter: "https://twitter.com/defidefenselaw",
    },
    {
      img: img58,
      name: "BANDIT",
      info: "Artist",
      twitter: "https://twitter.com/doodle2691",
    },
    {
      img: img59,
      name: "GREG NORMAN JR",
      info: "LinksDAO",
      twitter: "https://twitter.com/gregjrnorman",
    },
    {
      img: img60,
      name: "JUSTIN MELILLO",
      info: "CEO | Monaverse",
      twitter: "https://twitter.com/justinmmelillo",
    },
    {
      img: img61,
      name: "ELIANA",
      info: "Attorney",
      twitter: "https://twitter.com/eliana_esq",
    },
    {
      img: img62,
      name: "EDDIE GANGLAND",
      info: "Artist",
      twitter: "https://twitter.com/eddiegangland",
    },
    {
      img: img63,
      name: "JESSICA NEER MCDONALD",
      info: "Attorney",
      twitter: "https://twitter.com/neermcd",
    },
    {
      img: img64,
      name: "LEAH LAMARR",
      info: "Comedian",
      twitter: "https://twitter.com/leahlamarr",
    },
    {
      img: img65,
      name: "NFT Ignition",
      info: "Fame Lady Squad",
      twitter: "https://twitter.com/nftignition",
    },
    {
      img: img66,
      name: "TATTED",
      info: "NFT Latinos Unidos",
      twitter: "https://twitter.com/nftdefiland",
    },
    {
      img: img67,
      name: "RENDR",
      info: "Artist",
      twitter: "https://twitter.com/rendr_eth",
    },
    {
      img: img68,
      name: "GIOVANNI GUSSEN",
      info: "Smilesss",
      twitter: "https://twitter.com/giovannigussen",
    },
    {
      img: img69,
      name: "JOONS",
      info: "ClayMates",
      twitter: "https://twitter.com/clayjoons",
    },
    {
      img: img70,
      name: "SWAGGINS",
      info: "Chilled Kongs",
      twitter: "https://twitter.com/swagginscrypto",
    },
    {
      img: img71,
      name: "KAI BROWN",
      info: "Chainstitch Artist",
      twitter: "https://twitter.com/wearamrnft",
    },
    {
      img: img72,
      name: "AUSTIN HURWITZ",
      info: "Venice Music",
      twitter: "https://twitter.com/austin_hurwitz",
    },
    {
      img: img73,
      name: "BROOKE LACEY",
      info: "Technologist",
      twitter: "https://twitter.com/brookejlacey",
    },
    {
      img: img74,
      name: "CHRISTINE BARNUM",
      info: "Entrepreneur",
      twitter: "https://twitter.com/christinebarnum",
    },
    {
      img: img75,
      name: "BENNY",
      info: "Chilled Kongs",
      twitter: "https://twitter.com/bennyatthebank",
    },
    {
      img: img76,
      name: "BRITTNEY PIERRE",
      info: "Photographer | Filmmaker",
      twitter: "https://twitter.com/redactedpride",
    },
    {
      img: img77,
      name: "ALEXANDER MAYES",
      info: "Coinbase NFT",
      twitter: "https://twitter.com/alexandermayes",
    },
    {
      img: img78,
      name: "CREATIVEDAYS",
      info: "Artist",
      twitter: "https://twitter.com/creativeday5",
    },
    {
      img: img79,
      name: "ROC SOL",
      info: "Artist",
      twitter: "https://twitter.com/rocsolmiami",
    },
    {
      img: img80,
      name: "TYLER STOCKFIELD",
      info: "Web3 Consultant",
      twitter: "https://twitter.com/anonsvoice",
    },
    {
      img: img81,
      name: "KATHERINE BUGLIONE",
      info: "Artist",
      twitter: "https://twitter.com/_i_s_o_l_a_",
    },
    {
      img: img82,
      name: "JYOTHEE",
      info: "Artist",
      twitter: "https://twitter.com/jyxdi",
    },
    {
      img: img83,
      name: "QUEEN RUSSKIY",
      info: "Cryptodads",
      twitter: "https://twitter.com/queenrusskiy",
    },
    {
      img: img84,
      name: "ZE FLORIST",
      info: "Artist",
      twitter: "https://twitter.com/zeflorist",
    },
    {
      img: img85,
      name: "THE TECH RABBI",
      info: "Web3 Educator",
      twitter: "https://twitter.com/thetechrabbi",
    },
    {
      img: img86,
      name: "GAV BLAXBERG",
      info: "Wolf Financial",
      twitter: "https://twitter.com/wolf_financial",
    },
    {
      img: img87,
      name: "SER PUNK",
      info: "Web3CFL",
      twitter: "https://twitter.com/serpunketh",
    },
  ];
  const length = images.length;

  useEffect(() => {
    console.clear();
    const splicedImages = images.splice(0, 12);
    SetImages(splicedImages);
    console.log("splicedImages -> ", splicedImages);
  }, []);

  const LoadMoreIamges = () => {
    console.log(end);
    const splicedImages = images.splice(0, end);
    console.log("updatedImages -> ", splicedImages);
    SetImages(splicedImages);
    SetEnd(end + 12);
    if (end > length) {
      SetHideButton(true);
    }
  };
  return (
    <>
      <div class="sec-images">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <h2>
                FEATURED <span>SPEAKERS, ARTISTS, AND BUILDERS</span>
              </h2>
            </div>

            {Images &&
              Images.map((item) => (
                <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                  <div class="content-img">
                    <a href="#">
                      <div class="content-img-overlay"></div>
                      <img class="content-img-image" src={item.img} alt="" />
                      <div class="content-img-details fadeIn-bottom">
                        <h3 class="content-img-title">{item.name}</h3>
                        <p class="content-img-text">{item.info}</p>

                        <Link onClick={() => window.open(item.twitter)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-twitter"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                          </svg>
                        </Link>
                      </div>
                    </a>
                  </div>
                </div>
              ))}

            {!hideButton ? (
              <div class="col-12 text-center">
                <button
                  onClick={LoadMoreIamges}
                  type="button"
                  class="btn btn-primary btn-show"
                >
                  Show more
                  {/* <div class="spinner-border" role="status"> */}
                  {/* <span class="visually-hidden">Loading...</span> */}
                  {/* </div> */}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Speaker;
