import { Link } from "react-router-dom";
import img1 from "../_assets/images/img-b002.jpg";

const Beach = () => {
  return (
    <>
      <div id="accomodations" class="sec-w">
        <div class="container">
          <div class="row row-reverse640">
            <div class="col-md-5 met-img">
              <img src={img1} alt="" />
            </div>
            <div class="col-md-7 mt-auto mb-auto">
              <h2>
                EDEN ROC <span>MIAMI BEACH</span>
              </h2>
              <p>
                An icon designed by famed architect Morris Lapidus in 1955, Eden
                Roc Miami Beach brings plenty of nostalgic glamour to the modern
                beachfront resort experience. The elite of Old Hollywood were
                charmed by our timeless style and upbeat ambiance, and for
                today’s curious, carefree, culturally attuned guest the same
                still holds true at this legendary resort.
              </p>
              <p>
                Bright, spacious guest rooms with big balconies put you at the
                edge of the sea with direct ocean views. Three sparkling
                beachfront pools and an exclusive strip of beach invite you to
                let loose in a relaxed, fun, tropical setting. With vibrant
                nightlife and world-famous cultural attractions just minutes
                away, there's a little bit of everything for everybody here at
                Eden Roc.
              </p>
              <button
                onClick={() =>
                  window.open(
                    "https://be.synxis.com/?hotel=59764&config=groups&group=2022METAV"
                  )
                }
                class="btn btn-secondary"
              >
                MAKE RESERVATIONS
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Beach;
