import { Outlet } from "react-router-dom";
import Layout from "./layout";

function MainWebLayout({web3Data}) {
  return (
    <Layout web3Data={web3Data}>
      <Outlet/>
    </Layout>
  );
}

export default MainWebLayout;
