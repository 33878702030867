import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import customJs from "../_assets/js/custom";
import metaverseMiami from "../_assets/images/metaverse-miami.png";

const Navigation = ({web3Data}) => {
  useEffect(() => {
    customJs();
  }, []);

  console.log('nav', web3Data)

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <Link className="navbar-brand" to="/">
          <img src={metaverseMiami} alt="" />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </button>

        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/#speakers">
                SPEAKERS
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/#sponsors">
                SPONSORS
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/#venue" className="nav-link">
                VENUE
              </Link>
            </li>
           <li className="nav-item">
              <Link to="/#" className="nav-link">
               MARKETPLACE
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/#" className="nav-link">
               OPENSEA
              </Link>
            </li>
            <li className="nav-item">
              <button onClick={ web3Data.wallet_connect() } className="nav-link">
                { web3Data.addressDisplay }
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};
export default Navigation;
