const Faqs = () => {
  return (
    <>
      <div className="faq-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>
                FREQUENTLY <span>ASKED QUESTIONS</span>
              </h2>
            </div>

            <div className="col-12">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h3 className="accordion-header" id="heading001">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse001"
                      aria-expanded="false"
                      aria-controls="collapse001"
                    >
                      What makes Metaverse Miami different from other NFT
                      conferences?
                    </button>
                  </h3>
                  <div
                    id="collapse001"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading001"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>Metaverse Miami is a web3-native, tokenized Metaverse, NFT & Art innovation conference providing high value, curated & in-depth educational content, art experiences, networking, parties, performances, and immaculate vibes in one luxurious location. Our web3-native team is building a new conference paradigm that puts community first. That’s why we are offering our limited supply of perpetual & rentable NFT tickets. </p>
                      <p>Our Genesis Pass NFTs is the only way to access our events. Our NFT is seamlessly integrated into the entire event experience, not just as your way to get in the door, but in diverse and innovative ways that leverage the dynamic nature of NFT technology. Our NFT provides the opportunity to access to our 2022 conference and EVERY future event.</p>
                    </div>
                  </div>
                </div>

                

                <div className="accordion-item">
                  <h3 className="accordion-header" id="heading003">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse003"
                      aria-expanded="false"
                      aria-controls="collapse003"
                    >
                      What is the difference between your General Admission and VIP Passes?
                    </button>
                  </h3>
                  <div
                    id="collapse003"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading003"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>Our General Admission Genesis Pass NFT provides perpetual and rentable access to our events for one (1) person.</p>
                      <p>Our VIP Genesis Pass NFT provides a premium tier experience for our events. This year for Metaverse Miami 2022, for example, our VIP Pass holders are entitled to bring a +1 to the event where you both will enjoy access to our VIP Lounge and be able to attend our exclusive VIP Party.</p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h3 className="accordion-header" id="heading004">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse004"
                      aria-expanded="false"
                      aria-controls="collapse004"
                    >
                      How can I apply to speak at Metaverse Miami?
                    </button>
                  </h3>
                  <div
                    id="collapse004"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading004"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>We have been honored and humbled by the number of speaking requests that we have received this year. While we truly appreciate this outpouring of interest, we do not have an open application process for our 2022 speakers.</p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h3 className="accordion-header" id="heading005">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse005"
                      aria-expanded="false"
                      aria-controls="collapse005"
                    >
                      Are you currently looking for additional sponsors or
                      partners?
                    </button>
                  </h3>
                  <div
                    id="collapse005"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading005"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>If you are interested in sponsoring or partnering with Metaverse Miami, please send an email to sponsor@metaverse.miami and include "Sponsorship Inquiry" in the subject line. Thank you for supporting the web3 community!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;
