import { Link } from "react-router-dom";
import payCard from "../_assets/images/pay-card.png";
import tokenProof from "../_assets/images/tooken-proof.png";
import MiamiHerald from "../_assets/images/MiamiHerald.png";
import rugRadio from "../_assets/images/rug-radio.png";
import pieces from "../_assets/images/pieces.png";
import televisa from "../_assets/images/televisa.png";
import headerxsmin from "../_assets/images/header-xsm-min.jpg";
import headerMdMin from "../_assets/images/header-md-min.jpg";
import headerLgMin from "../_assets/images/header-lg-min.jpg";
import web3miami from "../_assets/js/web3miami";

const Header = ({web3Data}) => {
  console.log('header',web3Data);
  return (
    <>
      <div className="header">
        <div className="middle-content">
          <div className="container">
            <h1>
              <span>EDEN ROC</span>Miami Beach, FL
            </h1>
            <span className="datestyle">November 28-30, 2022</span>
            <h3>
              <button /*onClick = { web3Data.mint }*/>
                <span>MINT</span> GENESIS PASS <span>NFT</span>
              </button>
            </h3>
            <p>
              To purchase with CREDIT/DEBIT card <Link to="/" className="link">
                CLICK HERE
              </Link>
            </p>
            <img className="pay-card" src={payCard} alt="" />
            <h4>PRESENTED IN COOPERATION WITH</h4>

            <div className="logos-sec">
              <img src={tokenProof} alt="" />
              <img src={MiamiHerald} alt="" />
              <img src={rugRadio} alt="" />
              <img src={pieces} alt="" />
              <img src={televisa} alt="" />
            </div>
          </div>
        </div>
        <figure>
          <picture>
            <source
              media="(max-width: 480px)"
              srcset={headerxsmin}
              dataSrc={headerxsmin}
            />
            <source
              media="(max-width: 1024px)"
              srcset={headerMdMin}
              dataSrc={headerMdMin}
            />
            <source
              media="(min-width: 1025px)"
              srcset={headerLgMin}
              dataSrc={headerLgMin}
            />
            <img
              src={headerLgMin}
              dataSrc-base="images/"
              dataSrc={`"{xs:${headerxsmin},md:${headerMdMin},xl:${headerLgMin}}"`}
              alt=""
            />
          </picture>
        </figure>
      </div>
    </>
  );
};

export default Header;
