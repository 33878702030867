import React from "react";
import Navigation from "./navigation";
import Footer from "./footer";

const Layout = ({web3Data,  children }) => {
  return (
    <React.Fragment>
      <div class="wrapper">
        <Navigation web3Data={web3Data}/>
        <main web3data={web3Data}>{children}</main>
        <Footer />
      </div>
    </React.Fragment>
  );
};
export default Layout;
