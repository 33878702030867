/* eslint-disable no-unused-expressions */
import { Routes, Route } from "react-router-dom";
import "./App.css";
import MainWebLayout from "./_layout/main-web-layout";
import Home from "./_pages/home";
import "./_assets/js/bootstrap.bundle.min.js";

import web3miami from "./_assets/js/web3miami";

function App() {
    const web3Data = web3miami()

  return (
    <Routes>
      <Route path="/" exact element={<MainWebLayout web3Data={web3Data} />}>
        <Route index element={<Home web3Data={web3Data}/>} />
      </Route>
    </Routes>
  );
}

export default App;
