import Beach from "./beach";
import Faqs from "./faqs";
import Header from "./header";
import PassInfo from "./pass-info";
import Speaker from "./speaker";
import Sponser from "./sponsor";
import Event from "./event";

const Home = ({web3Data}) => {
  console.log('home',web3Data)
  return (
    <>
      <Header web3Data={web3Data} />
      <PassInfo />
      <Speaker />
      <Beach />
      <Faqs />
      <Sponser />
      <Event />
    </>
  );
};

export default Home;
