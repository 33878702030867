const Event = () => {
  return (
    <>
      <div id="venue" className="faq-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>
                CALENDAR <span>OF EVENTS</span>
              </h2>
            </div>

            <div className="col-12">
              <div className="event-card">
                <div className="heading">MONDAY - November 28, 2022</div>
                <div className="body">
                  <b className="primary-color">
                    6PM-10PM THE OFFICIAL METAVERSE MIAMI WELCOME PARTY
                  </b>
                  <p>
                    <span>
                      Our official welcome party kicks off in the Eden Roc
                      Garden sponsored by Atlas and Larkhaus!
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="event-card">
                <div className="heading">TUESDAY - November 29, 2022</div>
                <div className="body">
                  <b className="primary-color">
                    6PM-10PM THE OFFICIAL METAVERSE MIAMI WELCOME PARTY
                  </b>
                  <p>
                    <span>
                      Our official welcome party kicks off in the Eden Roc
                      Garden sponsored by Atlas and Larkhaus!
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="event-card">
                <div className="heading">WEDNESDAY - November 30, 2022</div>
                <div className="body">
                  <b className="primary-color">
                    6PM-10PM THE OFFICIAL METAVERSE MIAMI WELCOME PARTY
                  </b>
                  <p>
                    <span>
                      Our official welcome party kicks off in the Eden Roc
                      Garden sponsored by Atlas and Larkhaus!
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Event;
