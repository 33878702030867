import img1 from "../_assets/images/sp-001.jpg";
import img2 from "../_assets/images/sp-002.jpg";
import img3 from "../_assets/images/sp-003.jpg";
import img4 from "../_assets/images/sp-004.jpg";
import img5 from "../_assets/images/sp-005.jpg";
import img6 from "../_assets/images/sp-006.jpg";
import img7 from "../_assets/images/sp-007.jpg";
import img8 from "../_assets/images/sp-008.jpg";
import img9 from "../_assets/images/sp-009.jpg";
import img10 from "../_assets/images/amr.jpg";
import img11 from "../_assets/images/animus.jpg";
import img12 from "../_assets/images/artifex.jpg";
import img13 from "../_assets/images/beerdao.jpg";
import img14 from "../_assets/images/cfl.jpg";
import img15 from "../_assets/images/jeweler.jpg";
import img16 from "../_assets/images/nothing.jpg";
import img17 from "../_assets/images/omparticle.jpg";
import img18 from "../_assets/images/gather.jpg";

const Sponser = () => {
  return (
    <>
      <div id="sponsors" className="sponsored-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>
                <span>2022</span> EVENT SPONSORS
              </h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img1} alt="" />
            </div>
            <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img2} alt="" />
            </div>
            <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img3} alt="" />
            </div>
            <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img4} alt="" />
            </div>
            <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img5} alt="" />
            </div>
            <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img6} alt="" />
            </div>
            <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img7} alt="" />
            </div>
            <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img8} alt="" />
            </div>
            <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img9} alt="" />
            </div>
 <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img10} alt="" />
            </div>
 <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img11} alt="" />
            </div>
 <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img12} alt="" />
            </div>
 <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img13} alt="" />
            </div>
 <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img14} alt="" />
            </div>
 <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img15} alt="" />
            </div>
 <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img16} alt="" />
            </div>
 <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img17} alt="" />
            </div>
 <div className="col-lg-2 col-md-2 col-6">
              {" "}
              <img className="sp-img" src={img18} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sponser;
